import React, { createRef } from "react"
import { graphql } from "gatsby"

import * as styles from './showcase.module.scss';

import Layout from "../components/layout"
import Seo from "../components/seo"
import io from "socket.io-client"
import qr from "qrcode"

class TechDemo extends React.Component {

  constructor(props) {
    super(props)
    this.state = { value: 0 }
    this.canvas = createRef();
    this.image = createRef();
    this.receivedFirstUpdate = false;

    this.images = this.props.data.allFile.edges.map(edge => edge.node.publicURL);
    this.transitionSteps = 10;
  }

  componentDidMount() {
    this.prefetch(this.images);
    const socketIoPath = '/api/v1/socket.io';
    this.socket = io({path: socketIoPath});

    this.socket.on("connect", () => {
      if (this.roomId == null) {
        this.socket.on("joinedRoom", roomId => {
          this.roomId = roomId
          this.updateQRCode()
        })
      }
      this.socket.on('valueChange', (value) => {

        if (!this.receivedFirstUpdate) {
          this.image.current.scrollIntoView({block: "start", behavior: "smooth"});
          this.receivedFirstUpdate = true;
        }

        this.setState({
          value: value
        });
      })
      this.socket.emit("createRoom")
    })
    this.socket.on('error', err => {
      console.log(err);
    })
  }

  updateQRCode() {
    console.log(`Creating QR Code for ${process.env.SHOWCASE_REMOTE_URL}/?roomId=${this.roomId}`);
    qr.toCanvas(
      this.canvas.current,
      `${process.env.SHOWCASE_REMOTE_URL}/?roomId=${this.roomId}`,
      function(error) {
        if (error) console.error(error)
      }
    )
  }

  componentWillUnmount() {
    this.socket.disconnect();
  }

  setValue = event => {
    let value = event.target.value;

    value = Math.max(0, Math.min(this.images.length * this.transitionSteps, value));
    this.setState({ value });
  }

  prefetch(images) {
    for(let i = 0;i < images.length; i++) {
      var image = new Image();
      image.src = images[i];
    }
  }

  render() {
    let siteTitle = this.props.data.site.siteMetadata.title

    const opacities = this.images.map((img, i) => {
      let raw = this.state.value / (this.transitionSteps + 1) - i + 1;
      return `${Math.max(Math.min(raw, 1), 0)}`;

    })
    
    return (
      <Layout title={siteTitle}>
        <Seo title="Showcase" />

        <article>
          <header>
            <h3>Showcase</h3>
            <p>
              To facilitate controlling complex vehicles and machines, one might need some sliders and buttons. But those would totally clutter the screen! So instead - while playing the game - players will be able to connect to their game instance using a phone or tablet. You can try this out right now: bring the QR Code below in front of your phones camera and switch on your camera app! <i>On Android you might have to find out how to activate QR Code scanning, use an app or enable QR Code reading in the browser options (Samsung).</i>
            </p>
            <canvas className={styles.qrCode} ref={this.canvas}></canvas>
            <p>
              Now that you've loaded the site behind the QR Code, move the slider and reveal the hidden object!
            </p>
            {/* <input type="range" min="0" max={this.images.length * this.transitionSteps - 1} step="1" value={this.state.value} onChange={this.setValue}></input> */}
            <div className={styles.imageContainer}>
              {this.images.map((image, i) => 
                <img alt="" key={i} src={image} ref={this.image} style={{opacity: opacities[i]}}></img>
              )}
            </div>

          </header>
        </article>
      </Layout>
    )
  }
}

export default TechDemo

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: {
      absolutePath: {regex: "assets/showcase/"},
      extension: {eq: "jpg"}
    }, sort: {order: ASC, fields: base}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`
